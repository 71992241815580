export const environment = {
  production: false,
  stage: true,
  qas: false,
  version: '1.36.0-rc8',
  keys: {
    token: 'TOKEN_PROXY_KEY',
    refreshToken: 'REFRESH_TOKEN_PROXY_KEY',
    user: 'USER_PROXY_KEY',
  },
  config: {
    redirectToWhenAuthenticated: '/pages/dashboard',
    redirectToWhenUnauthenticated: '/auth/login',
    maxVideoSizeInMb: 1024,
    autoRefreshInterval: 10000,
    oraculoId: '26ac5294-e5d9-40da-a726-fc8e547fdf55',
    adminOrganizationId: 'd8a3133c-d28a-4cf3-9d5d-ec746e4bd270',
    organizationPortalIqr: '3d50d3dc-4c27-4d7d-b8e4-27e5e5ed132a',
    wordpressAdmin: 'https://guilhermemachado.com/wp-login.php?liga_auth_token={token}',
    sessionApiUrl: 'https://8p6so8w76k.execute-api.sa-east-1.amazonaws.com/dev',
    sessionCredential: '4987df9eb257312e69ae85c39f8fca597fc1d4bf599f60ddbd84fbf0c37e0156bee52d9417be808810738c686f8ee495',
    rankingPointsId: '0b9aa7b4-c73f-4499-a45f-db61c012f064',
  },
  api: {
    baseUrl: 'https://api.universidadeuqr.com.br/staging',
    auth: {
      login: '/auth/local',
    },
    users: {
      me: '/users/me',
    },
  },
};
